#company {
	article {
	    width: 80%;
	    height: auto;
	    margin: auto;
	    position: absolute;
	    left: 0px;
	    right: 0px;
	    top: 50%;
	    transform: translateY(-50%) scale(1) !important;
	}
}



#contact{
	article {
		width: 80%;
	    height: auto;
	    margin: auto;
	    position: absolute;
	    left: 0px;
	    right: 0px;
	    top: 50%;
	    transform: translateY(-50%) scale(1) !important;
	}
}


.news-subpage {
	.news-one {
		flex: 0 0 28%;
		width: 28%;
	}
}